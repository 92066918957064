// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-apps-pumpmate-index-tsx": () => import("./../../../src/pages/apps/pumpmate/index.tsx" /* webpackChunkName: "component---src-pages-apps-pumpmate-index-tsx" */),
  "component---src-pages-apps-pumpmate-privacy-tsx": () => import("./../../../src/pages/apps/pumpmate/privacy.tsx" /* webpackChunkName: "component---src-pages-apps-pumpmate-privacy-tsx" */),
  "component---src-pages-apps-winching-index-tsx": () => import("./../../../src/pages/apps/winching/index.tsx" /* webpackChunkName: "component---src-pages-apps-winching-index-tsx" */),
  "component---src-pages-apps-winching-privacy-tsx": () => import("./../../../src/pages/apps/winching/privacy.tsx" /* webpackChunkName: "component---src-pages-apps-winching-privacy-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

